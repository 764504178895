<template>
  <expansion-panel-creative
    :title="$t('main.ad_form.creative')"
    :removable="removable"
    :creative="creative"
    :creative-index="creativeIndex"
  >
    <template #content>
      <v-card-text class="pa-6">
        <c-text-field
          :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_TITLE, creativeIndex)"
          :value="creative.title"
          class="mb-3"
          :error-messages="errors[`creatives.${creativeIndex}.title`] || errors[`creatives.title`]"
          :label="$t('main.ad_form.title')"
          :placeholder="$t('main.ad_form.enter_the_title')"
          hide-details="auto"
          label-bold
          :hint="`${$t('main.recommended')}: ${adsDataRequirements.nativeAd.title} ${$t('main.ad_form.characters')}`"
          :counter="adsDataRequirements.nativeAd.title"
          @input="setCreative($event, 'title')"
          @focus="removeError([`creatives.${creativeIndex}.title`, `creatives.title`])"
        />

        <ad-url-field
          :selenium-id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_URL, creativeIndex)"
          :url="creative.url"
          :creative-index="creativeIndex"
          :placeholder="$t('main.ad_form.url_placeholder')"
          @input="setCreative($event, 'url')"
          @focus="removeError([`creatives.${creativeIndex}.url`, `creatives.url`])"
        />

        <c-textarea
          :value="creative.description"
          label-bold
          :error-messages="errors[`creatives.${creativeIndex}.description`] || errors[`creatives.description`]"
          hide-details="auto"
          :label="$t('main.ad_form.description')"
          :placeholder="$t('main.ad_form.enter_a_description')"
          :hint="`${$t('main.recommended')}: ${adsDataRequirements.nativeAd.description} ${$t('main.ad_form.characters')}`"
          :counter="adsDataRequirements.nativeAd.description"
          @input="setCreative($event, 'description')"
          @focus="removeError([`creatives.${creativeIndex}.description`, `creatives.description`])"
        />
        <file-upload
          :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_IMAGE_FILEPOND, creativeIndex)"
          ref="fileUpload"
          :value="imageId"
          :label="$t('main.ad_form.img_load', { maxImageSize })"
          types="image/jpeg, image/png, image/gif"
          :max-size="maxImageSize"
          store-type="media"
          class="mt-4"
          crop-size="200x200"
          image-crop-aspect-ratio="1:1"
          :error-messages="errors[`creatives.${creativeIndex}.image`] || errors[`creatives.image`]"
          @update="removeError([`creatives.${creativeIndex}.image`, `creatives.image`])"
          @input="setCreative($event, 'image')"
        />

        <v-checkbox
          color="primary"
          :label="labelActive"
          :error-messages="errors[`creatives.${creativeIndex}.is_active`]"
          :input-value="creative.isActive"
          hide-details="auto"
          @change="changeIsActive($event)"
        />
      </v-card-text>

      <v-card-actions v-if="isFinalElement && !getIsEditing" class="pa-6 pt-0">
        <c-btn
          :id="seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_ADD"
          color="primary"
          outlined
          large
          :block="isMobile"
          :label="$t('main.ad_form.add')"
          :icon-props="{
            icon: '$plus',
            size: '14'
          }"
          @click="addCreative()"
        />
      </v-card-actions>
    </template>
  </expansion-panel-creative>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import seleniumDynamicField from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import CTextarea from '@clickadilla/components/ui/CTextarea.vue'
  import FileUpload from '@/components/FileUpload.vue'
  import ExpansionPanelCreative from '@/components/ads/CreativeLayout/ExpansionPanelCreative.vue'
  import AdUrlField from '@/components/ads/AdUrlField.vue'

  export default {
    name: 'NativeAdCreative',
    components: {
      CTextarea,
      FileUpload,
      ExpansionPanelCreative,
      CTextField,
      AdUrlField,
      CBtn
    },
    props: {
      creative: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: Number,
        required: true
      },
      removable: Boolean
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['errors', 'creatives']),
      ...mapState('settings', ['adsDataRequirements']),
      ...mapGetters('settings', ['maxImageSize']),
      ...mapGetters('adForm', ['getIsEditing']),
      imageId() {
        return this.creative.image ? this.creative.image.id : null
      },
      labelActive() {
        const text = this.creative.isActive ? this.$t('main.ad_form.active') : this.$t('main.ad_form.not_active')
        return `${this.$t('main.ad_form.creative_is')} ${text}`
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      isFinalElement() {
        return this.creativeIndex === this.creatives.length - 1
      }
    },
    methods: {
      seleniumDynamicField,
      ...mapActions('adForm', ['removeError', 'addCreative']),
      setCreative(value, name) {
        const creativeCopy = JSON.parse(JSON.stringify(this.creative))
        creativeCopy[name] = value
        this.$emit('update-creative', creativeCopy)
      },
      changeIsActive($event) {
        this.setCreative($event, 'isActive')
        this.removeError([`creatives.${this.creativeIndex}.is_active`, 'creatives.is_active'])
      }
    }
  }
</script>
