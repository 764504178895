<template>
  <div>
    <c-tabs-toggle-buttons
      :value="skinGroupType"
      :buttons="skinGroupsValues"
      @input="setSkinGroupType($event)"
    >
      <template #label>
        <div
          class="black--text text-subtitle-1 text-sm-body-2 font-weight-medium mb-1"
        >
          {{ $t("main.ad_form.channel_skins") }}
        </div>
      </template>
    </c-tabs-toggle-buttons>
    <p class="mt-2 info-lighten--text text-caption">
      {{ $t('main.ad_form.select_type_advertising_format') }}
    </p>
  </div>
</template>

<script>
  import CTabsToggleButtons from '@clickadilla/components/ui/CTabsToggleButtons.vue'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'ChannelSkinsToggler',
    components: { CTabsToggleButtons },
    computed: {
      ...mapState('adForm', ['skinGroupType']),
      skinGroupsValues() {
        return [
          {
            text: this.$t('main.ad_form.web'),
            value: 'web'
          },
          {
            text: this.$t('main.ad_form.telegram_mini_app'),
            value: 'tma'
          }
        ]
      }
    },
    methods: {
      ...mapActions('adForm', ['setSkinGroupType'])
    }
  }
</script>
