<template>
  <div>
    <v-sheet v-if="filteredInPageSkinGroup.length">
      <span class="text-body-2 font-weight-medium black--text">
        {{ $t('main.ad_form.priority_ad_skins') }}
      </span>
      <v-container fluid class="pa-0 mt-2">
        <v-row no-gutters class="cards-row">
          <v-col
            v-for="inPageSkinGroup in filteredInPageSkinGroup"
            :key="inPageSkinGroup.id"
            class="pa-1"
          >
            <c-btn
              :id="
                seleniumDynamicField(
                  seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_SKIN_GROUP,
                  inPageSkinGroup.id
                )
              "
              block
              depressed
              :class="['rounded secondary-lighten', { 'border-primary': creative.inPageSkinGroup.id === inPageSkinGroup.id }]"
              height="62"
              @click="changeSkinGroup(inPageSkinGroup)"
            >
              <div class="d-flex flex-column justify-center">
                <div>
                  <v-icon size="16" :color="creative.inPageSkinGroup.id === inPageSkinGroup.id ? 'primary' : 'info'">
                    {{ getSkinGroupIcon(inPageSkinGroup.name) }}
                  </v-icon>
                </div>
                <div class="text-body-2 text-capitalize mt-2">
                  {{ inPageSkinGroup.name }}
                </div>
              </div>
            </c-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-expand-transition mode="out-in">
        <div v-show="!creative.inPageSkinGroup.id" class="mt-2">
          <v-alert color="info" text class="text-body-2 mb-0 w-100">
            {{ $t('main.ad_form.alert_creative') }}
          </v-alert>
        </div>
      </v-expand-transition>
    </v-sheet>
    <v-card elevation="0" class="black pt-6 pb-14 pb-md-6 mt-6 px-4 px-md-13">
      <v-card-title
        class="white--text justify-center text-subtitle-2 text-uppercase pa-0"
      >
        {{ $t('main.ad_form.preview') }}
      </v-card-title>
      <v-card-text class="pa-0 mt-4">
        <v-expand-transition>
          <div>
            <v-alert color="info">
              <div class="d-flex align-center white--text text-caption">
                <v-icon size="16" color="white" class="mr-4">
                  $warning-outlined
                </v-icon>
                {{ $t('main.ad_form.graphic_creatives_info') }}
              </div>
            </v-alert>
          </div>
        </v-expand-transition>
      </v-card-text>
      <v-card-text
        v-if="
          defaultInPageSkin
            || (selectedSkinGroup && selectedSkinGroup.inPageSkins.length)
        "
        class="preview-container pa-0"
      >
        <v-overlay
          absolute
          :value="!creative.icon?.src"
          opacity="0.90"
          class="text-center"
        >
          <div class="overlay-text text-caption">
            {{ $t('main.ad_form.upload_icon') }}
          </div>
        </v-overlay>
        <div
          v-if="adNetwork.settings.inPagePreviewTagId && !isMobile"
          class="rounded overflow-hidden"
        >
          <div class="actions-container d-flex justify-end">
            <c-btn
              icon
              :icon-props="{
                size: 20,
                color: 'primary',
                icon: '$update'
              }"
              @click="updatePreview()"
            />
          </div>
          <in-page-preview
            :key="iframeKey"
            :settings="previewParams"
            :height="calculateHeightPreview"
            :tag-id="adNetwork.settings.inPagePreviewTagId"
          />
        </div>
        <v-container v-if="selectedSkinGroup" fluid class="pb-0 pt-2">
          <v-row no-gutters class="mx-n4">
            <v-col
              v-for="skin in selectedSkinGroup.inPageSkins"
              :key="skin.id"
              class="pa-1"
              cols="auto"
            >
              <c-btn
                :id="
                  seleniumDynamicField(
                    seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_SKIN,
                    skin.id
                  )
                "
                depressed
                :color="
                  creative.inPageSkin.id === skin.id ? 'primary' : 'secondary'
                "
                @click="changeSkin(skin)"
              >
                <span
                  :class="[
                    creative.inPageSkin.id === skin.id
                      ? 'white--text'
                      : 'black--text'
                  ]"
                >{{ skin.name }}</span>
              </c-btn>
            </v-col>
          </v-row>
        </v-container>
        <c-btn
          v-if="isMobile"
          depressed
          block
          class="mt-4"
          color="primary"
          :label="$t('main.ad_form.show_preview')"
          @click="previewModalIsShown = true"
        />
        <v-dialog
          :value="previewModalIsShown"
          @input="previewModalIsShown = false"
        >
          <v-card :height="calculateHeightPreview" class="overflow-hidden">
            <in-page-preview
              v-if="adNetwork.settings.inPagePreviewTagId"
              :key="iframeKey"
              :height="calculateHeightPreview"
              :settings="previewParams"
              :tag-id="adNetwork.settings.inPagePreviewTagId"
            />
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import seleniumDynamicField from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import InPagePreview from '@/components/ads/InPagePreview.vue'
  import skinGroups from '@/types/skin-groups.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  const {
    IN_PAGE_SELECTED_SKIN,
    IN_PAGE_SELECTED_SKIN_GROUP
  } = GTM_EVENTS

  export default {
    name: 'InPageGraphicCreatives',
    components: { InPagePreview, CBtn },
    props: {
      creative: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: [Number, String],
        required: true
      }
    },
    data() {
      return {
        seleniumIds,
        iframeKey: 1,
        previewModalIsShown: false
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      ...mapGetters('collections', ['inPageSkinGroups', 'defaultInPageSkin']),
      ...mapGetters('settings', ['getAdFormatsByAdNetwork']),
      ...mapState('adForm', ['adFormatType', 'skinGroupType']),
      hasVisibleTma() {
        return this.getAdFormatsByAdNetwork.some((item) => item.type === adFormats.IN_PAGE_AD && item.sourceType === 'tg-site')
          && this.skinGroupType === 'tma'
      },
      calculateHeightPreview() {
        return this.skinGroupType === 'tma' ? '650' : '330'
      },
      selectedSkinGroup() {
        return this.filteredInPageSkinGroup.find(({ id }) => id === this.creative.inPageSkinGroup.id)
      },
      filteredInPageSkinGroup() {
        return this.hasVisibleTma ? this.inPageSkinGroups.filter((item) => item.isTma) : this.inPageSkinGroups.filter((item) => !item.isTma)
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      previewParams() {
        return {
          description: this.creative.description ?? '',
          tdsId: this.creative.inPageSkin.tdsId || this.defaultInPageSkin?.tdsId,
          title: this.creative.title ?? '',
          icon: this.creative.icon?.src ?? '',
          image: this.creative.image?.src ?? ''
        }
      }
    },
    watch: {
      previewParams() {
        this.updatePreview()
      }
    },
    methods: {
      ...mapActions('adForm', ['removeError']),
      setCreative(value, name) {
        const creativeCopy = JSON.parse(JSON.stringify(this.creative))
        creativeCopy[name] = value
        this.$emit('update-creative', creativeCopy)
      },
      changeSkinGroup(inPageSkinGroup) {
        const creativeCopy = JSON.parse(JSON.stringify(this.creative))
        if (inPageSkinGroup.id === this.creative.inPageSkinGroup.id) {
          creativeCopy.inPageSkinGroup = {}
          creativeCopy.inPageSkin = {}
        } else {
          creativeCopy.inPageSkinGroup = inPageSkinGroup
          creativeCopy.inPageSkin = inPageSkinGroup.inPageSkins[0] ?? {}
        }
        this.$emit('update-creative', creativeCopy)

        gtmPush({ event: IN_PAGE_SELECTED_SKIN_GROUP })
      },
      changeSkin(skin) {
        if (skin.id === this.creative.inPageSkin.id) return

        this.setCreative(skin, 'inPageSkin')
        gtmPush({ event: IN_PAGE_SELECTED_SKIN })
      },
      updatePreview() {
        this.iframeKey += 1
      },
      getSkinGroupIcon(skinGroupName) {
        return {
          [skinGroups.STANDARD]: '$check',
          [skinGroups.DATING]: '$heart',
          [skinGroups.UTILITY]: '$all',
          [skinGroups.GAMBLING]: '$gambling',
          [skinGroups.BETTING]: '$spades'
        }[skinGroupName.toLowerCase()] ?? '$check'
      },
      seleniumDynamicField
    }
  }
</script>

<style scoped lang="scss">
  .preview-container {
    position: relative;
  }
  .overlay-text {
    max-width: 400px;
    margin-bottom: -40px;
    @media screen and (min-width: 600px) {
      margin-bottom: unset;
    }
  }

  .actions-container {
    background-color: rgb(245 245 245 / 0.05);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      height: 1px;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      width: 95%;
      background-color: var(--v-black-base);
    }
  }
</style>
