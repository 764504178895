var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.filteredInPageSkinGroup.length)?_c('v-sheet',[_c('span',{staticClass:"text-body-2 font-weight-medium black--text"},[_vm._v(" "+_vm._s(_vm.$t('main.ad_form.priority_ad_skins'))+" ")]),_c('v-container',{staticClass:"pa-0 mt-2",attrs:{"fluid":""}},[_c('v-row',{staticClass:"cards-row",attrs:{"no-gutters":""}},_vm._l((_vm.filteredInPageSkinGroup),function(inPageSkinGroup){return _c('v-col',{key:inPageSkinGroup.id,staticClass:"pa-1"},[_c('c-btn',{class:['rounded secondary-lighten', { 'border-primary': _vm.creative.inPageSkinGroup.id === inPageSkinGroup.id }],attrs:{"id":_vm.seleniumDynamicField(
                _vm.seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_SKIN_GROUP,
                inPageSkinGroup.id
              ),"block":"","depressed":"","height":"62"},on:{"click":function($event){return _vm.changeSkinGroup(inPageSkinGroup)}}},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('div',[_c('v-icon',{attrs:{"size":"16","color":_vm.creative.inPageSkinGroup.id === inPageSkinGroup.id ? 'primary' : 'info'}},[_vm._v(" "+_vm._s(_vm.getSkinGroupIcon(inPageSkinGroup.name))+" ")])],1),_c('div',{staticClass:"text-body-2 text-capitalize mt-2"},[_vm._v(" "+_vm._s(inPageSkinGroup.name)+" ")])])])],1)}),1)],1),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.creative.inPageSkinGroup.id),expression:"!creative.inPageSkinGroup.id"}],staticClass:"mt-2"},[_c('v-alert',{staticClass:"text-body-2 mb-0 w-100",attrs:{"color":"info","text":""}},[_vm._v(" "+_vm._s(_vm.$t('main.ad_form.alert_creative'))+" ")])],1)])],1):_vm._e(),_c('v-card',{staticClass:"black pt-6 pb-14 pb-md-6 mt-6 px-4 px-md-13",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"white--text justify-center text-subtitle-2 text-uppercase pa-0"},[_vm._v(" "+_vm._s(_vm.$t('main.ad_form.preview'))+" ")]),_c('v-card-text',{staticClass:"pa-0 mt-4"},[_c('v-expand-transition',[_c('div',[_c('v-alert',{attrs:{"color":"info"}},[_c('div',{staticClass:"d-flex align-center white--text text-caption"},[_c('v-icon',{staticClass:"mr-4",attrs:{"size":"16","color":"white"}},[_vm._v(" $warning-outlined ")]),_vm._v(" "+_vm._s(_vm.$t('main.ad_form.graphic_creatives_info'))+" ")],1)])],1)])],1),(
        _vm.defaultInPageSkin
          || (_vm.selectedSkinGroup && _vm.selectedSkinGroup.inPageSkins.length)
      )?_c('v-card-text',{staticClass:"preview-container pa-0"},[_c('v-overlay',{staticClass:"text-center",attrs:{"absolute":"","value":!_vm.creative.icon?.src,"opacity":"0.90"}},[_c('div',{staticClass:"overlay-text text-caption"},[_vm._v(" "+_vm._s(_vm.$t('main.ad_form.upload_icon'))+" ")])]),(_vm.adNetwork.settings.inPagePreviewTagId && !_vm.isMobile)?_c('div',{staticClass:"rounded overflow-hidden"},[_c('div',{staticClass:"actions-container d-flex justify-end"},[_c('c-btn',{attrs:{"icon":"","icon-props":{
              size: 20,
              color: 'primary',
              icon: '$update'
            }},on:{"click":function($event){return _vm.updatePreview()}}})],1),_c('in-page-preview',{key:_vm.iframeKey,attrs:{"settings":_vm.previewParams,"height":_vm.calculateHeightPreview,"tag-id":_vm.adNetwork.settings.inPagePreviewTagId}})],1):_vm._e(),(_vm.selectedSkinGroup)?_c('v-container',{staticClass:"pb-0 pt-2",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-n4",attrs:{"no-gutters":""}},_vm._l((_vm.selectedSkinGroup.inPageSkins),function(skin){return _c('v-col',{key:skin.id,staticClass:"pa-1",attrs:{"cols":"auto"}},[_c('c-btn',{attrs:{"id":_vm.seleniumDynamicField(
                  _vm.seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_SKIN,
                  skin.id
                ),"depressed":"","color":_vm.creative.inPageSkin.id === skin.id ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.changeSkin(skin)}}},[_c('span',{class:[
                  _vm.creative.inPageSkin.id === skin.id
                    ? 'white--text'
                    : 'black--text'
                ]},[_vm._v(_vm._s(skin.name))])])],1)}),1)],1):_vm._e(),(_vm.isMobile)?_c('c-btn',{staticClass:"mt-4",attrs:{"depressed":"","block":"","color":"primary","label":_vm.$t('main.ad_form.show_preview')},on:{"click":function($event){_vm.previewModalIsShown = true}}}):_vm._e(),_c('v-dialog',{attrs:{"value":_vm.previewModalIsShown},on:{"input":function($event){_vm.previewModalIsShown = false}}},[_c('v-card',{staticClass:"overflow-hidden",attrs:{"height":_vm.calculateHeightPreview}},[(_vm.adNetwork.settings.inPagePreviewTagId)?_c('in-page-preview',{key:_vm.iframeKey,attrs:{"height":_vm.calculateHeightPreview,"settings":_vm.previewParams,"tag-id":_vm.adNetwork.settings.inPagePreviewTagId}}):_vm._e()],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }