<template>
  <div>
    <ad-name-field />
    <ad-brand-field />
    <ad-common-url-field ad-format-type="nativeAd" />
    <creatives ref="creatives" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import AdNameField from '@/components/ads/AdNameField.vue'
  import AdCommonUrlField from '@/components/ads/AdCommonUrlField.vue'
  import Creatives from '@/components/ads/Creatives.vue'
  import AdBrandField from '@/components/ads/AdBrandField.vue'

  export default {
    name: 'NativeAdForm',
    components: {
      AdBrandField,
      AdNameField,
      AdCommonUrlField,
      Creatives
    },
    computed: {
      ...mapState('settings', ['adNetwork'])
    }
  }
</script>
