<template>
  <v-container fluid class="d-flex flex-wrap align-center pa-0">
    <span class="text-subtitle-1 mr-2"> {{ title }} </span>

    <creative-info-icon v-if="adFormats.helpUrl" :link="adFormats.helpUrl" class="mr-2 ">
      <span>
        {{ $t('main.ad_form.requirements_for_creative') }}
      </span>
    </creative-info-icon>

    <v-spacer />

    <what-is-page-ads
      v-if="!getIsEditing && adFormats.helpVideoUrl"
      :ad-name="adFormats.title"
      :help-video-url="adFormats.helpVideoUrl"
    />
  </v-container>
</template>

<script>
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import { mapGetters, mapState } from 'vuex'
  import WhatIsPageAds from '@/components/ads/WhatIsPageAds/Index.vue'
  import CreativeInfoIcon from '@/components/CreativeInfoIcon.vue'

  export default {
    name: 'HeaderAdForm',
    components: {
      WhatIsPageAds,
      CreativeInfoIcon
    },
    props: {
      adFormat: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapGetters('settings', ['helpVideosUrls', 'helpUrls']),
      ...mapGetters('adForm', ['getIsEditing']),
      ...mapState('adForm', ['isDuplicate']),
      ...mapState('settings', ['adNetwork']),
      adFormats() {
        return (
          {
            [adFormats.POPUNDER]: {
              title: this.$t(`main.ad_format_types.${adFormats.POPUNDER}`),
              helpVideoUrl: this.helpVideosUrls.popunders,
              helpUrl: this.helpUrls.popunder_requirements
            },
            [adFormats.BANNER]: {
              title: this.$t(`main.ad_format_types.${adFormats.BANNER}`),
              helpVideoUrl: this.helpVideosUrls.banners,
              helpUrl: this.helpUrls.banner_requirements
            },
            [adFormats.WEB_PUSH]: {
              title: this.$t(`main.ad_format_types.${adFormats.WEB_PUSH}`),
              helpVideoUrl: this.helpVideosUrls.web_pushes,
              helpUrl: this.helpUrls.web_push_requirements
            },
            [adFormats.IN_STREAM_AD]: {
              title: this.$t(`main.ad_format_types.${adFormats.IN_STREAM_AD}`),
              helpVideoUrl: this.helpVideosUrls.in_streams,
              helpUrl: this.helpUrls.in_stream_requirements
            },
            [adFormats.NATIVE_AD]: {
              title: this.$t(`main.ad_format_types.${adFormats.NATIVE_AD}`),
              helpVideoUrl: this.helpVideosUrls.natives
            },
            [adFormats.DIRECT_LINK]: {
              title: this.$t(`main.ad_format_types.${adFormats.DIRECT_LINK}`),
              helpVideoUrl: this.helpVideosUrls.direct_links
            },
            [adFormats.INTERSTITIAL]: {
              title: this.$t(`main.ad_format_types.${adFormats.INTERSTITIAL}`),
              helpVideoUrl: this.helpVideosUrls.interstitials,
              helpUrl: this.helpUrls.full_page_interstitial
            },
            [adFormats.IOS_CALENDAR]: {
              title: this.$t(`main.ad_format_types.${adFormats.IOS_CALENDAR}`),
              helpVideoUrl: this.helpVideosUrls.ios_calendars
            },
            [adFormats.GALLERY]: {
              title: this.$t(`main.ad_format_types.${adFormats.GALLERY}`),
              helpVideoUrl: this.helpVideosUrls.gallery_ads,
              helpUrl: this.helpUrls.gallery_ad_requirements
            },
            [adFormats.IN_PAGE_AD]: {
              title: this.adNetwork.settings.renameInPageFormat
                ? this.$t('main.ad_format_types.tg_interstitial')
                : this.$t(`main.ad_format_types.${adFormats.IN_PAGE_AD}`),
              helpVideoUrl: this.helpVideosUrls.in_page_ads
            }
          }[this.adFormat] || {}
        )
      },
      title() {
        if (this.getIsEditing) {
          return `${this.$t('main.ad_form.editing')} ${this.adFormats.title}`
        }
        if (this.isDuplicate) {
          return `${this.$t('main.ad_form.duplicate')} ${this.adFormats.title}`
        }
        return `${this.$t('main.ad_form.create')} ${this.adFormats.title}`
      }
    }
  }
</script>
