<template>
  <div>
    <ad-name-field />
    <ad-priority v-if="rotationIsRandom" />
    <ad-smart-threshold-field v-if="rotationTypeIsSmart && !creativeIsSingle" />
    <ad-common-url-field ad-format-type="inPage" />
    <channel-skins-toggler v-if="canVisibleChannelSkins" />
    <creatives v-if="!inPageSkinGroupsAreLoading" ref="creatives" />
    <rotation-type-select v-if="!creativeIsSingle" class="my-6" />
    <ad-smart-threshold-field v-if="rotationTypeIsSmart && !creativeIsSingle" />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import AdNameField from '@/components/ads/AdNameField.vue'
  import AdSmartThresholdField from '@/components/ads/AdSmartThresholdField.vue'
  import Creatives from '@/components/ads/Creatives.vue'
  import AdCommonUrlField from '@/components/ads/AdCommonUrlField.vue'
  import RotationTypeSelect from '@/components/ads/RotationTypeField.vue'
  import AdPriority from '@/components/ads/AdPriority.vue'
  import ChannelSkinsToggler from '@/components/ChannelSkinsToggler.vue'

  export default {
    name: 'InPageAdForm',
    components: {
      AdPriority,
      AdSmartThresholdField,
      AdNameField,
      RotationTypeSelect,
      Creatives,
      AdCommonUrlField,
      ChannelSkinsToggler
    },
    computed: {
      ...mapGetters('adForm', ['creativeIsSingle', 'rotationTypeIsSmart', 'rotationIsRandom']),
      ...mapGetters('settings', ['getAdFormatsByAdNetwork']),
      ...mapState('collections', ['inPageSkinGroupsAreLoading']),
      ...mapGetters('collections', ['inPageSkinGroups']),
      ...mapState('adForm', ['adFormatType', 'creatives', 'skinGroupType']),
      canVisibleChannelSkins() {
        return this.getAdFormatsByAdNetwork.some((item) => item.type === adFormats.IN_PAGE_AD && item.sourceType === 'tg-site')
      }
    },
    watch: {
      skinGroupType: {
        handler() {
          if (this.skinGroupType !== this.getDefaultSkinType()) {
            this.resetSkinGroupCreatives()
          }
        }
      }
    },
    async mounted() {
      await this.fetchInPageSkinGroups()
      this.setDefaultSkinType()
    },
    methods: {
      ...mapActions('collections', ['fetchInPageSkinGroups']),
      ...mapActions('adForm', ['setCreatives', 'setSkinGroupType']),
      resetSkinGroupCreatives() {
        const creatives = JSON.parse(JSON.stringify(this.creatives))
        const resetCreatives = creatives.map((creative) => ({
          ...creative,
          inPageSkinGroup: {},
          inPageSkin: {}
        }))
        this.setCreatives(resetCreatives)
      },
      getDefaultSkinType() {
        if (!this.creatives || !Array.isArray(this.creatives)) {
          return 'web'
        }

        const creativeWithSkin = this.creatives.find(
          (creative) => creative.inPageSkin?.id
        )

        if (!creativeWithSkin) {
          return 'web'
        }

        const groupId = creativeWithSkin.inPageSkin?.id ?? null

        const matchedGroup = this.inPageSkinGroups.find((group) => group.inPageSkins.some((skin) => skin.id === groupId))

        if (!matchedGroup) {
          return 'web'
        }

        return matchedGroup.isTma === false ? 'web' : 'tma'
      },
      setDefaultSkinType() {
        const defaultSkinType = this.getDefaultSkinType()
        this.setSkinGroupType(defaultSkinType)
      }
    }
  }
</script>
