<template>
  <expansion-panel-creative
    :title="$t('main.ad_form.creative')"
    :removable="removable"
    :creative="creative"
    :creative-index="creativeIndex"
  >
    <template #content>
      <v-card-text class="pa-6">
        <v-row class="pa-3">
          <v-col
            cols="12"
            :md="rotationIsRandom ? 9 : 12"
            :lg="rotationIsRandom ? 10 : 12"
            class="pa-0 pr-md-2"
          >
            <ad-url-field
              :selenium-id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_URL, creativeIndex)"
              :url="creative.url"
              :creative-index="creativeIndex"
              :is-loading="isLoading"
              :placeholder="$t('main.ad_form.url_placeholder')"
              @input="setCreative($event, 'url')"
              @focus="removeError([`creatives.${creativeIndex}.url`, `creatives.url`])"
            />
          </v-col>
          <v-col v-if="rotationIsRandom" cols="12" md="3" lg="2" class="pa-0">
            <creative-priority
              :creative-index="creativeIndex"
              :creative="creative"
              @input="setCreative($event, 'priority')"
              @focus="removeError(`creatives.${creativeIndex}.priority`)"
            />
          </v-col>
        </v-row>
        <ads-auto-complete
          v-if="isAutoComplete && !isLoading && creative.url"
          class="mb-2"
          :creative-url="creative.url"
          @auto-complete="autoCompleteHandler()"
        />

        <ad-formats-url-tokens
          v-if="creativeTokensLinks.length"
          :value="creative.url"
          :tokens="creativeTokensLinks"
          class="pt-2 mb-4"
          @input="setCreative($event, 'url')"
        />

        <c-text-field
          :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_TITLE, creativeIndex)"
          ref="titleRef"
          :value="creative.title"
          :error-messages="errors[`creatives.${creativeIndex}.title`] || errors[`creatives.title`]"
          :label="$t('main.ad_form.title')"
          :placeholder="$t('main.ad_form.enter_the_title')"
          hide-details="auto"
          label-bold
          @input="onInputChange($event, 'title')"
          @focus="removeError([`creatives.${creativeIndex}.title`, `creatives.title`])"
        />
        <errors
          v-if="validationWarnings[`creatives.${creativeIndex}.title`]"
          class="mb-2 mt-n3 pl-3"
          :error-messages="validationWarnings[`creatives.${creativeIndex}.title`]"
        />
        <url-tokens-text
          v-if="creativeTokensTexts.length"
          :tokens="creativeTokensTexts"
          class="mb-4"
          @selected-token="addCreativeToken({ token: $event, ref: 'titleRef', fieldName: 'title' })"
        />

        <c-textarea
          :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_BODY, creativeIndex)"
          ref="descriptionRef"
          :value="creative.description"
          label-bold
          :error-messages="
            errors[`creatives.${creativeIndex}.description`] || errors[`creatives.description`]
          "
          hide-details="auto"
          :label="$t('main.ad_form.description')"
          :placeholder="$t('main.ad_form.enter_a_description')"
          @input="onInputChange($event, 'description')"
          @focus="removeError([`creatives.${creativeIndex}.description`, `creatives.description`])"
        />
        <errors
          v-if="validationWarnings[`creatives.${creativeIndex}.description`]"
          class="mb-3 mt-n3 pl-3"
          :error-messages="validationWarnings[`creatives.${creativeIndex}.description`]"
        />
        <url-tokens-text
          v-if="creativeTokensTexts"
          :tokens="creativeTokensTexts"
          class="mb-4"
          @selected-token="addCreativeToken({ token: $event, ref: 'descriptionRef', fieldName: 'description' })"
        />

        <span class="text-body-2 font-weight-medium black--text">
          {{ $t('main.ad_form.graphic_creatives') }}
        </span>
        <v-container fluid>
          <v-row>
            <v-col class="pt-2 px-0 pr-md-4" cols="12" md="4">
              <file-upload
                :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_ICON_FILEPOND, creativeIndex)"
                ref="fileUploadIcon"
                :value="iconId"
                :label="$t('main.ad_form.icon_size_maximum', { extensions: 'JPG, PNG, GIF', maxImageSize })"
                types="image/jpeg, image/png, image/gif"
                :max-size="maxImageSize"
                store-type="media"
                crop-size="192x192"
                :error-messages="
                  errors[`creatives.${creativeIndex}.icon`] || errors['creatives.icon']
                "
                @update="removeError([`creatives.${creativeIndex}.icon`, `creatives.icon`])"
                @input="setCreative($event, 'icon')"
              />
            </v-col>

            <v-col class="pt-2 px-0" cols="12" md="8">
              <file-upload
                :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_IMAGE_FILEPOND, creativeIndex)"
                ref="fileUploadImage"
                :value="imageId"
                :label="`${$t('main.ad_form.image_size_maximum', {
                  maxImageSize
                })}`"
                types="image/jpeg, image/png, image/gif"
                :max-size="maxImageSize"
                store-type="media"
                crop-size="360x240"
                :error-messages="
                  errors[`creatives.${creativeIndex}.image`] || errors[`creatives.image`]
                "
                @update="removeError([`creatives.${creativeIndex}.image`, `creatives.image`])"
                @input="setCreative($event, 'image')"
              />
            </v-col>
          </v-row>
        </v-container>

        <in-page-graphic-creatives :creative="creative" :creative-index="creativeIndex" @update-creative="$emit('update-creative', $event)" />

        <div class="mt-2 info-lighten--text text-right text-caption">
          {{ $t('main.ad_form.alert_creative_performance') }}
        </div>
      </v-card-text>

      <v-card-actions v-if="addCreativeIsShown" class="pa-6 pt-0">
        <c-btn
          :id="seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_ADD"
          color="primary"
          outlined
          large
          :block="isMobile"
          class="text-lowercase"
          :label="$t('main.ad_form.add')"
          :icon-props="{
            icon: '$plus',
            size: '14'
          }"
          @click="addCreative()"
        />
      </v-card-actions>
    </template>
  </expansion-panel-creative>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CTextarea from '@clickadilla/components/ui/CTextarea.vue'
  import debounce from 'debounce'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import seleniumDynamicField from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import AdFormatsUrlTokens from '@/components/ads/AdFormatsUrlTokens/Index.vue'
  import AdsAutoComplete from '@/components/ads/AdsAutoComplete.vue'
  import AdUrlField from '@/components/ads/AdUrlField.vue'
  import ExpansionPanelCreative from '@/components/ads/CreativeLayout/ExpansionPanelCreative.vue'
  import adsRepository from '@/services/repositories/ads-repository.js'
  import UrlTokensText from '@/components/ads/UrlTokensText.vue'
  import InPageGraphicCreatives from '@/components/ads/InPageAdForm/InPageGraphicCreatives.vue'
  import FileUpload from '@/components/FileUpload.vue'
  import CreativePriority from '@/components/ads/CreativePriority.vue'

  export default {
    name: 'InPageAdCreative',
    components: {
      CreativePriority,
      FileUpload,
      InPageGraphicCreatives,
      UrlTokensText,
      Errors,
      AdFormatsUrlTokens,
      AdsAutoComplete,
      CTextField,
      CTextarea,
      CBtn,
      ExpansionPanelCreative,
      AdUrlField
    },
    props: {
      creative: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: [Number, String],
        required: true
      },
      removable: Boolean
    },
    data() {
      return {
        debouncedDocumentMeta: null,
        debouncedValidateKeywords: null,
        isLoading: false,
        isAutoComplete: false,
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['errors', 'creatives', 'validationWarnings']),
      ...mapGetters('settings', ['maxImageSize']),
      ...mapGetters('adForm', ['getIsEditing', 'creativeTokensLinks', 'creativeTokensTexts', 'rotationIsRandom']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      isFinalElement() {
        return this.creativeIndex === this.creatives.length - 1
      },
      addCreativeIsShown() {
        return this.isFinalElement && !this.getIsEditing
      },
      imageId() {
        return this.creative.image ? this.creative.image.id : null
      },
      iconId() {
        return this.creative.icon ? this.creative.icon.id : null
      }
    },
    created() {
      this.debouncedDocumentMeta = debounce(this.fetchDocumentMeta, 500)
      this.debouncedValidateKeywords = debounce(this.validateKeywords, 2000)
    },
    methods: {
      seleniumDynamicField,
      ...mapActions('adForm', ['removeError', 'addCreative', 'validateKeywords']),
      setCreative(value, name) {
        const creativeCopy = JSON.parse(JSON.stringify(this.creative))
        creativeCopy[name] = value
        this.$emit('update-creative', creativeCopy)

        if (name === 'url' && value) {
          this.debouncedDocumentMeta()
        }
      },
      onInputChange(value, fieldName) {
        this.setCreative(value, fieldName)
        this.debouncedValidateKeywords()
      },
      async fetchDocumentMeta() {
        this.isLoading = true
        const params = {
          url: this.creatives[this.creativeIndex].url
        }
        try {
          const response = await adsRepository.fetchDocumentMeta(params)
          this.setCreative(response, 'autoGeneratedCreative')
          this.isAutoComplete = true
        } catch {
          this.isAutoComplete = false
        }
        this.isLoading = false
      },
      async autoCompleteHandler() {
        const creativeCopy = JSON.parse(JSON.stringify(this.creative))

        creativeCopy.title = creativeCopy.autoGeneratedCreative.title
        creativeCopy.description = creativeCopy.autoGeneratedCreative.description
        creativeCopy.image = creativeCopy.autoGeneratedCreative.image
        creativeCopy.icon = creativeCopy.autoGeneratedCreative.image

        this.$emit('update-creative', creativeCopy)
        this.isAutoComplete = false
      },
      addCreativeToken({ token, ref, fieldName }) {
        const positions = this.$refs[ref].getSelectedPositons()
        const textWithToken = [
          this.creative[fieldName].slice(0, positions.start),
          `[${token}]`,
          this.creative[fieldName].slice(positions.end)
        ].join('')
        this.onInputChange(textWithToken, fieldName)
      }
    }
  }
</script>
