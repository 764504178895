<template>
  <div class="d-flex flex-wrap">
    <div v-for="token in tokens" :key="token" class="pa-1">
      <v-chip
        label
        small
        @click="$emit('selected-token', token)"
      >
        <span class="text-truncate text-lowercase black--text">
          {{ token }}
        </span>
      </v-chip>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UrlTokensText',
    props: {
      tokens: {
        type: Array,
        required: true
      }
    }
  }
</script>
